<template>
  <div id="app">
    <keep-alive>
      <!--router-view组件是一个 functional 组件，渲染路径匹配到的视图组件-->
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!--不需要缓存组件-->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- <router-view /> -->
    <!-- <van-tabbar route>
      <van-tabbar-item replace to="/home" icon="bag">
        商城
      </van-tabbar-item>
      <van-tabbar-item replace to="/recreation" icon="fire">
        玩耍
      </van-tabbar-item>
      <van-tabbar-item replace to="/me" icon="manager">
        我的
      </van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>

<script>
// import { getShopName } from "../src/utils/api";
export default {
  name: "App",
  data(){
    return{

    }
  },
  created(){
    // this.getshopName()
  },
  methods:{
    // //获取店铺名称
    // async getshopName() {
    //   let path = window.location.pathname.split("/")[1];
    //   let params = {
    //     shopAlias: path,
    //   };
      
    //   await getShopName(params).then((res) => {
    //     sessionStorage.setItem('shopMsg',res.data)
    //   });
    // },
  }
};
</script>

<style lang="scss" scoped>
html,
body,
#app {
  width: 100%;
  height: 100%;
}
/deep/ .van-tabbar-item--active {
  color: #FF804D !important;
}
</style>
