import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter)

const routes = [
    {
        path: '/:shopAlias',
        redirect: '/:shopAlias/home',
    },
    {
        path: '/:shopAlias/redirectMid',
        name: 'redirectMid',
        component: () => import("../view/redirectMid/index")
    },
    {
        path: '/:shopAlias/middleware',
        name: 'middleware',
        component: () => import("../view/middleware/index")
    },
    {
        path: '/:shopAlias/login',
        name: 'login',
        component: () => import("../view/login/index"),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/:shopAlias/bindPhone',
        name: 'bindPhone',
        component: () => import("../view/login/bindPhone"),
        meta: {
            title: '绑定手机号'
        }
    },
    {
        path: '/:shopAlias/home',
        name: 'home',
        component: () => import("../view/Home/index"),
        meta: {
            title: '首页',
            isBack: false,
            keepAlive: true,
        }
    },
    {
        path: '/:shopAlias/search',
        name: 'search',
        component: () => import("../view/Home/search"),
        meta: {
            title: '商品搜索',
            isBack: false,
            keepAlive: true,

        }
    },
    {
        path: '/:shopAlias/searchMore',
        name: 'searchMore',
        component: () => import("../view/Home/searchMore"),
        meta: {
            title: '商城搜索'
        }
    },
    {
        path: '/:shopAlias/detail',
        name: 'detail',
        component: () => import("../view/Home/detail"),
        meta: {
            title: '详情'
        }
    },
    {
        path: '/:shopAlias/detailSelf',
        name: 'detailSelf',
        component: () => import("../view/Home/detailSelf"),
        meta: {
            title: '详情'
        }
    },
    {
        path: '/:shopAlias/trans',
        name: 'trans',
        component: () => import("../view/Home/trans"),
        meta: {
            title: '文章详情'
        }
    },
    {
        path: '/:shopAlias/me',
        name: 'me',
        component: () => import("../view/Me/index"),
        meta: {
            title: '个人中心'
        }
    },
    {
        path: '/:shopAlias/recreation',
        name: 'recreation',
        component: () => import("../view/recreation/index"),
        meta: {
            title: '玩耍'
        }
    },
    {
        path: '/:shopAlias/city',
        name: 'city',
        component: () => import("../view/recreation/city"),
        meta: {
            title: '城市列表'
        }
    },
    {
        path: '/:shopAlias/myCollection',
        name: 'myCollection',
        component: () => import("../view/myCollection/index"),
        meta: {
            title: '我的收藏'
        }
    },
    {
        path: '/:shopAlias/instruction',
        name: 'instruction',
        component: () => import("../view/instruction/index"),
        meta: {
            title: '使用说明'
        }
    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


export default router