import Vue from 'vue';
import App from './App.vue';
import router from './router';
// import store from './store';

import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: '7CNVgklukCz4WdogWmOvNsKvGwPEtWhU&s=1'
})
//清除浏览器默认样式
import '../node_modules/normalize.css/normalize.css'

//自适应rem
import '../node_modules/amfe-flexible/index.js'


//fastclick
import FastClick from 'fastclick';
FastClick.attach(document.body);

import '../static/css/reset.css';
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
// vant全局按需引入
import { Dialog,Picker,Popup,Checkbox, CheckboxGroup, Empty, IndexBar, IndexAnchor, Rate, Tag, Field, Search, GoodsAction, GoodsActionIcon, GoodsActionButton, Button, Toast, Tabbar, Icon, TabbarItem, Swipe, SwipeItem, Lazyload, Grid, GridItem, List, PullRefresh, Cell, Tab, Tabs } from 'vant';
Vue.use(Tabbar);
Vue.use(Tag);
Vue.use(Dialog);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Empty);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Rate);
Vue.use(Field);
Vue.use(Search);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Button);
Vue.use(TabbarItem);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Cell);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(Toast);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router,
  // store
}).$mount('#app')